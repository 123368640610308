@import "../node_modules/bootstrap-icons/font/bootstrap-icons";
// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
$nav-horizontal-width: 300px;

.sidenav {
    width: 100vw;
    height: 100%;
    padding-left: ($spacer * .5);
    padding-right: ($spacer * .5);
    background-color: $dark;

    @include media-breakpoint-up(md) {
        width: $nav-horizontal-width;
    }
}